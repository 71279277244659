// import homePageImg from './assets/img/homepage_home.jpeg';
// import homePageAboutImg from './assets/img/homepage_about.jpg';
import { withRouter } from 'react-router-dom';
import React from 'react';
import qs from 'query-string';
import { URL_ROUTE_USER } from '../utils/constants';
import { ReactP5Wrapper } from "react-p5-wrapper";
import { c_button } from "../sketches/c_button";
import { axis_button } from "../sketches/axis_button";
import { Q3_ORIENTATIONS } from '../utils/constants';
import { withTranslation, useTranslation } from "react-i18next";
import AudioGuide from '../components/AudioGuide'

import cartoonOne from "../assets/img/cartoon1.svg";
import cartoonTwo from "../assets/img/cartoon2.svg";
import cartoonBlank from "../assets/img/cartoonBLANK.jpeg";
import cartoonLeft from "../assets/img/cartoonCoverLeftEye.svg";
import cartoonRight from "../assets/img/cartoonCoverRightEye.svg";
import cartoonLeftGlass from "../assets/img/cartoonCoverLeftEyeGlasses.svg";
import cartoonRightGlass from "../assets/img/cartoonCoverRightEyeGlasses.svg";
import { useRealtime } from '../hooks/useRealtime';

class MPageTests extends React.Component {
  state = {
      pageStatus: 5,
      axis: 0,
      axis_idx: 0,
      init: true,
      first: true,
      nextPage: false,
      changePage: false,
      left: false,
      nextTest: false,
      openAnswer: false,
      q_idx: 0,
      loading: true,
  };

  async componentWillMount() {
    console.log('componentWillMount');
    await fetch(URL_ROUTE_USER + '/getStatus' + window.location.search)
      .then(response => response.json())
      .then(res => {
        const token = window.location.search.split('&')[1].split('=')[1];

        this.reloadState(res);
        this.setState({ loading: false });

        // eslint-disable-next-line react-hooks/rules-of-hooks
        const realtime = useRealtime();
        realtime.listener(`test-${token}`, 'm-update-message', async (data) => {
          await this.pollingStatus(data);
        });
      });
  }

  reloadState(data) {
    let { code, pageStatus, infos: { finalized } = {} } = data;

    if (pageStatus === 8 || code === "E010005" || finalized) {
      this.props.history.replace({ pathname: '/mend' });
      this.props.history.push({ pathname: '/mend' });
    } else {
      const { axis, infos: { changePage, left, nextTest, q_idx, openAnswer } } = data;
      console.log('setStatus in getStatus mtests');
      this.setState({ ...this.state, pageStatus, axis, changePage, left, nextTest, q_idx, openAnswer });
      console.log('before setState in getStatus mtests', this.state);

      this.pollingStatus(data);
    }
  }

  componentDidUpdate() {
      window.addEventListener('popstate', () => {
          this.props.history.go(1);
      });
  }

  componentWillUnmount() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const realtime = useRealtime();
    realtime.disconnect('test-channel')
  }

  goNext(v) {
    console.log(URL_ROUTE_USER + '/sendAnswer' + window.location.search + `&answer=${v}`);

    fetch(URL_ROUTE_USER + '/sendAnswer' + window.location.search + `&answer=${v}`)
      .then(response => response.json())
      .then(data => {
        if (data.message === "accepted" && this.state.pageStatus === 6 && (this.state.init || this.state.axis_idx !== 0)) {
          let axis_idx = this.state.axis_idx + 1
          if (axis_idx === Q3_ORIENTATIONS.length) {
              axis_idx = 0
          }
          console.log("accepted")
          this.setState({ ...this.state, axis: Q3_ORIENTATIONS[axis_idx], init: false, axis_idx });
        }
      });
  }

  async prepareToAnswer() {
    const token = window.location.search.split('&')[1].split('=')[1];

    return fetch(URL_ROUTE_USER + "/prepareToAnswer?token=" + token, {
      method: "POST",
      body: JSON.stringify({infos: {
        pageStatus: this.state.pageStatus,
        changePage: false,
        left: this.state.left,
        nextTest: this.state.nextTest,
        openAnswer: true,
        first: false,
      }}),
      headers: {"Content-type": "application/json; charset=UTF-8"}
    })
    .then(response => response.json())
    .then(data => {
      this.reloadState(data);
    });
  }

  redirectToEnd() {
    this.props.history.replace({ pathname: '/mend' });
    this.props.history.push({ pathname: '/mend' });
  }

  pollingStatus(data) {  
    console.log('resp getInfoStatus mtests', data);
    console.log('state mtests', this.state);
    const { pageStatus, infos } = data || {};
    if (pageStatus === 8 || (infos && infos.finalized)) {
      return this.redirectToEnd();
    }

    if(infos && infos.changePage) {
      console.log('infos and changePage', this.state);
      let { changePage, left, nextTest, q_idx, openAnswer } = infos;
      this.setState({ ...this.state, changePage, left, nextTest, q_idx, openAnswer });
    }
  }

  render() {
      if (this.state.loading) {
        return null;
      }
      console.log('this.state', this.state);
      const size = 80;

      const cartoon_arr= [
        cartoonOne,
        cartoonTwo,
        cartoonBlank,
        cartoonRight,
        cartoonLeft,
        cartoonRightGlass,
        cartoonLeftGlass,
      ];

      const getCartoon = (pageStatus, q_idx) => {
        console.log(pageStatus, q_idx);
        let cartoon_idx;
        if (q_idx === 0) {
          cartoon_idx = pageStatus - 1;
        } else if (q_idx >= 8) {
          cartoon_idx = (q_idx % 2) + 5;
        } else {
          cartoon_idx = (q_idx % 2) + 3;
        }
        // console.log('image ->>>>', q_idx, cartoon_idx)
        return cartoon_idx;
      }
      // console.log('this.state -->>', this.state);
      return (
          <div className="mobile-tests">

              <div className="main-view">
                {(this.state.changePage || !this.state.openAnswer) && 
                  <FinalizedPage
                    state={this.state}
                    image={cartoon_arr[getCartoon(this.state.pageStatus, (this.state.q_idx+1) || 0)]}
                  />}
                  {
                      (this.state.changePage === false && this.state.openAnswer) && (
                          <div className="main-view-inset">
                            
                              <h2>{this.state.pageStatus === 6 ? this.props.t('Select which side is clearer') : this.props.t('Select what you see')}</h2>

                              <div className="main-view-inset-wrapper">
                                  {this.state.pageStatus !== 6 && <div className="centerv">
                                      {this.state.pageStatus < 7 && <span onClick={() => { this.goNext(2) }}>
                                          <ReactP5Wrapper sketch={c_button} i={90} r={size} />
                                      </span>}
                                      <div className="centerh">
                                          <span style={{ paddingRight: '110px' }} onClick={() => { this.goNext(1) }}>
                                              <ReactP5Wrapper sketch={c_button} i={getOrientation(1, this.state.axis, this.state.pageStatus)} r={size} />
                                          </span>
                                          <span onClick={() => { this.goNext(this.state.pageStatus < 7 ? 3 : 2) }}>
                                              <ReactP5Wrapper sketch={c_button} i={getOrientation(2, this.state.axis, this.state.pageStatus)} r={size} />
                                          </span>
                                      </div>
                                      {this.state.pageStatus < 7 && <span onClick={() => { this.goNext(4) }}>
                                          <ReactP5Wrapper sketch={c_button} i={270} r={size} />
                                      </span>}
                                  </div>}

                                  {this.state.pageStatus === 6 && <div className="centerv">
                                      <div className="centerh">
                                          <span style={{ paddingRight: '90px' }} onClick={() => { this.goNext(1) }}>
                                              <ReactP5Wrapper sketch={axis_button} i={getOrientation(1, this.state.axis, this.state.pageStatus)} r={60} />
                                          </span>
                                          <span onClick={() => { this.goNext(3) }}>
                                              <ReactP5Wrapper sketch={axis_button} i={getOrientation(2, this.state.axis, this.state.pageStatus)} r={60} />
                                          </span>
                                      </div>
                                  </div>}
                              </div>
                          </div>
                      )
                  }




              </div>

              <div className="main-nav">
                  {this.state.openAnswer && (
                      <div className="button-light" onClick={() => { this.goNext(5) }}>
                          {this.props.t("Can't Tell")}
                      </div>
                  )}
                  {(!this.state.openAnswer) && (
                    <div className="button-default" onClick={async () => await this.prepareToAnswer()}>
                        {this.props.t("Next Stage")}
                    </div>
                  )}
              </div>
          </div>
      )
  }
}

function FinalizedPage(props) {
  const { t } = useTranslation();
  const query = qs.parse(window.location.search);
  return (
    <>
      <div className="main-view-inset">
        <div className="connectionContent" style={{position: "inherit"}}>
          <div>
            <img
              src={props.image}
              alt=""
              style={{ width: "70%" }}
            />
          </div>
          <div className="connectionContentTitle">
            <h1>{t("Stage Complete!")}</h1>
          </div>
          <div className="connectionContentText">
            {(props.state.left === true) && 
              (<h2 style={{ fontSize: '1.2rem', color: '#0db2e3' }}>{t("Cover your RIGHT eye then press 'Next Stage'")}</h2>)}
              
              {(props.state.left === true) && (<div style={{marginTop: "35px" }}>
							<AudioGuide 
								lang={query.lang}
								sound="mpage15right"
							/>
						</div>)}
            {(props.state.left === false || props.state.left === undefined) && 
              (<h2 style={{ fontSize: '1.2rem', color: '#0db2e3' }}>{t("Cover your LEFT eye then press 'Next Stage'")}</h2>)}
              {(props.state.left === false || props.state.left === undefined) && (<div style={{marginTop: "35px" }}>
							<AudioGuide 
								lang={query.lang}
								sound="mpage15left"
							/>
						</div>)}
          </div>
        </div>
      </div>
    </>
  )
}

// or value -> actual orientation for p5
function getOrientation(or, axis, pageStatus) {
    let theta = 0
    let delta = (pageStatus === 6) ? 90 : 180
    // left side
    if (axis >= 0 && axis < 90) {
        theta = axis + (or - 1) * delta
        // right side
    } else if (axis >= 90 && axis < 180) {
        theta = axis + (or) * delta
    } else {
        console.log('invalid axis: ' + axis)
    }
    return theta;
}

export default withTranslation()(withRouter(MPageTests));
