
import Pusher from 'pusher-js';

// Pusher.logToConsole = true;

class Realtime {
  instance = null;
  pusher = null;

  static getInstance() {
    if (!this.instance) {
        this.instance = new Realtime()
        this.instance.connect();
    }
    return this.instance;
  }

  connect() {
    // TODO: replace to ENV VAR
    this.pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER
    });

    this.pusher.connection.bind("state_change", function (states) {
      // TODO: listener connected/disconnected event to show message to users
      // states = {previous: 'oldState', current: 'newState'}
      console.log("Connection current state is " + states.current);
    });
  }

  disconnect(channelName) {
    console.log('disconnecting');
    this.pusher.unsubscribe(channelName);
  }

  listener(channelName, event, callback) {
    let channel = this.pusher.channel(channelName);
    if (!channel) {
      channel = this.pusher.subscribe(channelName);
    } else {
      channel.unbind(event);
    }
    channel.bind(event, callback);
  }
}

export const useRealtime = () => {
  return Realtime.getInstance();
};