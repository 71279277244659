export const useSpecsDropdown = (start, end, step) => {
  const options = [];
  for (let i = start; i <= end; step ? i += step : i++) {
    const value = step ? i.toFixed(2) : i
    options.push(
      <option key={value} value={value} selected={value === '0.00'}>
        {value}
      </option>
    );
  }
  return <select>
    {options}
  </select>;
};